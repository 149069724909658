.exercise-card {
  /* UI Properties */
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
  padding-left: 1vw;
  min-height: 50vh;
  margin-bottom: 2vh;
}

.body-region-title {
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(---84bd00-◀︎);
  text-align: left;
  font: normal normal normal 14px/21px Kiro;
  letter-spacing: 0.28px;
  color: var(--main-color);
  opacity: 1;
  text-align: center;
}

.exercise-title {
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal bold 18px/24px Kiro;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
  text-align: center;
}

.prescription {
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(---7c878e-◀︎);
  font: normal normal normal 16px/21px Kiro;
  letter-spacing: 0.28px;
  color: #7c878e;
  opacity: 1;
  text-align: center;
}

.exercise-description {
  overflow: hidden;
}
.video-content {
  width: 80%;
}

ul {
  list-style-position: outside;
}

.exercise-note {
  color: grey;
  font-style: italic;
  font-size: 16px;
  padding-left: 10px;
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .mobile-width {
    min-width: 100%;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
}
