$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 900px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1770px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 900px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1770px,
);

$success: #84bd00;

@import "~bootstrap/scss/bootstrap.scss";
