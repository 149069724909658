/* Mobile Screen Styles */
@media only screen and (min-width: 280px) {
  .profile-card {
    margin-bottom: 5vh;
  }
  .profile-card-image {
    height: 10vh;
    border-radius: 50%;
    margin-bottom: 1vh;
  }
  .profile-card-name {
    font-weight: bold;
  }
  .profile-card-mail {
    color: #7c878e;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
}
