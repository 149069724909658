.sidebar-properties {
  padding-top: 2vh;
  padding-left: 1vw;
  border-right: #dcdcdc solid;
}
.sidebar-header {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---84bd00-◀︎);
  text-align: left;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: var(--main-color);
  opacity: 1;
  border-bottom: 1px #dcdcdc solid;
}
.sub-menu-item {
  margin-bottom: 5vh;
  letter-spacing: var(--unnamed-character-spacing-0-32);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 16px/24px Kiro;
  letter-spacing: 0.32px;
  color: #7c878e;
  opacity: 1;
}

.sb-main-content-header {
  padding-top: 2vh;
  border-bottom: 1px #dcdcdc solid;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal normal 20px/30px Kiro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

a.active .sub-menu-item {
  letter-spacing: var(--unnamed-character-spacing-0-32);
  text-align: left;
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.table-column-title {
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(---7c878e-◀︎);
  text-align: left;
  font: normal normal normal 15px/24px Kiro;
  letter-spacing: 0.3px;
  color: #7c878e;
  opacity: 1;
}

.table-row {
  margin-top: 2vh;
}

.profile-input-field {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  padding-left: 0.5vw;
}

.about-sop {
  margin-top: 3vh;
}

.disclaimer {
  margin-top: 10vh;
}

.select-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  padding-left: 0.5vw;
  /*min-width: 10vw;*/
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .sidebar-properties {
    display: none;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .sidebar-properties {
    display: block;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
}
