.spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-control-prev {
  height: 300px;
}

.carousel-control-next {
  height: 300px;
}

.carousel .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-prev-icon {
  visibility: visible;
}
