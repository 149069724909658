.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a {
  text-decoration: none;
  color: #7c878e;
}

a:hover {
  text-decoration: none;
  color: var(--main-color);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.show-more-text {
  color: darkgrey;
  text-decoration: none;
  font-weight: normal;
}

.show-more-text::before {
  content: "+ ";
}

.show-more-text:focus,
.show-more-text:hover {
  text-decoration: none;
  outline: none;
}

.wrapper {
  padding: 20px;
}

.sophyapp-button {
  background-color: var(--main-color);
}

.main_background {
  /* Layout Properties */
  top: 0px;
  left: 0px;
  height: 100vh;
  /* UI Properties */
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.app-header {
  min-height: 7vh;
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 1vh;
  padding-left: 1vh;
  border-bottom: #dcdcdc solid;
  border-width: medium;
}

.app-menu {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-right: #dcdcdc solid;
  border-width: thin;
}

.menu-row {
  min-height: 7vh;
  text-align: center;
}

.menu-title {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menu-row:hover {
  background-color: var(--background-active);
}

.menu-row:active {
  background-color: var(--background-active);
}

.menu-footer {
  position: fixed;
  bottom: 1vh;
  padding-left: 1vw;
}
.col {
  padding-left: 0;
}

.program-overview {
  margin: 1vh 1vw 9vh 1vw;
  background-color: white;
  border: #dcdcdc solid;
  border-width: thin;
  overflow-y: scroll;
  height: 95vh;
}

.program-container-short {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
}

.searchbar {
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  /* border: 1px solid var(--unnamed-color-e5e7e8); */
  background: #f5f5f5 0% 0% no-repeat padding-box;
  /* border: 1px solid #e5e7e8; */
  border-radius: 20px;
  opacity: 1;
  height: 30%;
}

.spacer {
  padding-right: 1vw;
}

.active {
  text-decoration: none;
  color: var(--main-color);
  background-color: var(--background-active);
}

a.active .menu-row {
  background-color: var(--background-active);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .header-icons {
    text-align: right;
  }
  .header-icon {
    margin-right: 5px;
  }
  .exit-icon {
    width: 20px;
    height: 20px;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .header-icon {
    margin: none;
  }
  .profile-icon {
    display: none;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .exit-icon {
    width: 25px;
    height: 25px;
  }
  .help-icon {
    height: 25px;
    margin-right: -5px;
  }
}
