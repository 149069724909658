.program-overview-container {
  margin: 2vh;
  margin-bottom: 100px;
}

.app-header-image-class {
  max-height: 80px;
}
.program-overview-row {
  background: var(--———-others-———) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cacfd2);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  opacity: 1;
  height: 10vh;
  margin-bottom: 2vh;
  margin-right: 0.5vw;
}

.workout-thumbnail {
  margin-top: 1vh;
}

.program-title {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---000000-◀︎);
  text-align: left;
  font: normal normal bold 24px/32px Kiro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 2vh;
}

.program-progress-title {
  padding-top: 2vh;
}

.program-progress-graph {
  padding-bottom: 2vh;
}

.exercise-details-wrapper {
  margin-top: 2vh;
}

.last-of-type {
  margin-top: 20px;
  display: none;
}

.program-exercise-row:last-of-type {
  margin-bottom: 60px;
}

.range-slider {
  width: 100%;
}

-webkit-slider-thumb:active {
  background-color: var(--main-color);
}
-webkit-slider-thumb,
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
  box-shadow: var(--main-color);
}

.custom-range::-webkit-slider-thumb {
  background: var(--main-color);
}

.custom-range::-moz-range-thumb {
  background: var(--main-color);
}

.custom-range::-ms-thumb {
  background: var(--main-color);
}

.bs-button-color {
  background: var(--main-color);
  border: 1px var(--main-color) solid;
}

.bs-button-color:hover {
  background: #55bd00;
  border: 1px var(--main-color) solid;
}

.program-exercise-row {
  margin-bottom: 20px;
}

.btn-start-program {
  margin-top: 1vh;
  border: 1px solid var(--unnamed-color-cacfd2);
  border: 1px solid #cacfd2;
  border-radius: 20px;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0.18px;
  text-align: center;
  padding: 5px 0px 2px 5px;
  letter-spacing: var(--unnamed-character-spacing-0-3);
  font: normal normal normal 15px/26px Kiro;
  letter-spacing: 0.3px;
  color: #7c878e;
  width: 8vw;
}

.btn-feedback-program {
  margin-top: 1vh;
  background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-color: var(--main-color);
  opacity: 1;
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--———-others-———);
  text-align: left;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  width: 8vw;
  text-align: center;
  padding: 5px 0px 2px 5px;
}

.btn-previous-next {
  margin-top: 1vh;
  background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-color: var(--main-color);
  opacity: 1;
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--———-others-———);
  text-align: left;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  padding: 5px 0px 2px 5px;
  width: 100px;
}

.btn-previous-next:disabled {
  background: #d0d0d0;
  border-color: #d0d0d0;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-disabled {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  color: black !important;
}

/* Mobile Screen Styles */
@media only screen and (min-width: 360px) {
  .program-overview-row-active {
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-cacfd2);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 60vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    border: 1px solid var(--unnamed-color-cee499);
    background: var(--background-active) 0% 0% no-repeat padding-box;
    border: 1px solid #cee499;
  }
  .program-overview-row-inactive {
    border: 1px solid var(--unnamed-color-cacfd2);
    border-radius: 5px;
    opacity: 1;
    height: 60vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    border: 1px solid #cee499;
    background: var(--unnamed-color-fbf1f1) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-efbab8);
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #efbab8;
    border-radius: 5px;
    opacity: 1;
  }
  .mobile-title {
    margin-top: 2vh;
  }
  .mobile-feedback-details {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal bold 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #7c878e;
  }
  .mobile-feedback-title-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);

    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: var(--main-color);
    opacity: 1;
  }
  .mobile-feedback-title-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }

  .mobile-progress-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .mobile-progress-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-overview {
    margin: 0;
  }
  .app-header-image {
    margin-bottom: 2px;
  }

  .breadcrumb {
    margin-top: 10px;
  }
  .breadcrumb a {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
      var(--unnamed-line-spacing-27) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-18);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 18px/27px Kiro;
    letter-spacing: 0.18px;
    color: var(--main-color);
    opacity: 1;
  }
  .btn-start-program {
    width: 50vw;
  }
  .btn-feedback-program {
    width: 80vw;
    height: 35px;
  }
  .program-properties {
    border-right: none;
    margin-bottom: 20px;
  }
  .program-finished {
    margin-bottom: 30px;
  }
  .btn-feedback-program-in-properties {
    display: none;
  }
  .program-progress-title-active {
    padding-top: 2vh;
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .program-progress-title-inactive {
    padding-top: 2vh;
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-property {
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(---7c878e-◀︎);
    text-align: left;
    letter-spacing: 0.32px;
    color: #7c878e;
    opacity: 1;
  }
  /* .program-overview-container {
    margin: 2vh;
    margin-bottom: 500px;
  } */

  .fake-program-card {
    height: 400px;
  }
}

/* Tablet Screen Styles */
@media only screen and (min-width: 768px) {
  .btn-start-program {
    width: 20vw;
  }
  .btn-feedback-program {
    width: 20vw;
  }
  .profile-card-mobile {
    display: none;
  }
  .program-overview-row-active {
    background: var(--———-others-———) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-cacfd2);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 15vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    background: var(--background-active) 0% 0% no-repeat padding-box;
    overflow: hidden;
  }
  .program-overview-row-inactive {
    border: 1px solid #cacfd2;
    border-radius: 5px;
    opacity: 1;
    height: 15vh;
    margin-bottom: 2vh;
    margin-right: 0.5vw;
    background: #fbf1f1 0% 0% no-repeat padding-box;
    border: 1px solid #efbab8;
    overflow: hidden;
  }
  .mobile-title {
    display: none;
  }
  .mobile-details {
    display: none;
  }
  .mobile-feedback-details {
    display: none;
  }
  .mobile-feedback-title-active,
  .mobile-feedback-title-inactive {
    display: none;
  }
  .mobile-progress-active,
  .mobile-progress-inactive {
    display: none;
  }
  .mobile-progress-bar {
    display: none;
  }
  .mobile-progress-title {
    display: none;
  }
  .program-thumbnail {
    width: 100%;
    border-radius: 2px;
  }
  .program-overview {
    margin: 1vh 1vw 9vh 1vw;
  }
  .app-header-image {
    margin-bottom: 0;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .program-properties {
    border-right: #dcdcdc solid;
    border-width: 1px;
    margin-bottom: 0;
  }
  .program-finished {
    display: none;
  }
  .btn-feedback-program-in-properties {
    display: block;
  }

  .program-overview-container {
    margin: 2vh;
    margin-bottom: 100px;
  }
  .program-progress-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: var(--main-color);
    opacity: 1;
  }
  .program-progress-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
      var(--unnamed-line-spacing-21) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px Kiro;
    letter-spacing: 0.28px;
    color: #c92627;
    opacity: 1;
  }
  .program-progress-bar-active {
    background: var(---84bd00-◀︎) 0% 0% no-repeat padding-box;
    background: var(--main-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
  }
  .program-progress-bar-inactive {
    background: var(--unnamed-color-c92627) 0% 0% no-repeat padding-box;
    background: #c92627 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
  }
  .feedback-title-active {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);

    color: var(---84bd00-◀︎);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: var(--main-color);
    opacity: 1;
  }
  .feedback-title-inactive {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-kiro);
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(--unnamed-color-c92627);
    text-align: left;
    font: normal normal normal 16px/24px Kiro;
    letter-spacing: 0.32px;
    color: #c92627;
    opacity: 1;
  }
  .feedback-details {
    letter-spacing: var(--unnamed-character-spacing-0-28);
    color: var(---7c878e-◀︎);
    text-align: left;
    font: normal normal bold 14px/21px Kiro;
    letter-spacing: 0.28px;
    color: #7c878e;
  }
}

/* Notebook Small Screen Styles */
@media only screen and (min-width: 1024px) {
  .btn-start-program {
    width: 15vw;
  }
  .btn-feedback-program {
    width: 15vw;
  }
}

/* Notebook Screen Styles */
@media only screen and (min-width: 1200px) {
  .btn-start-program {
    width: 8vw;
  }
  .btn-feedback-program {
    width: 8vw;
  }
}

/* Large Screen Styles */
@media only screen and (min-width: 1400px) {
  .program-overview-row {
    height: 18vh;
  }
}

/* Extra Large Screen Styles */
@media only screen and (min-width: 1770px) {
  .program-overview-row {
    height: 20vh;
  }
}
